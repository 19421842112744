import React from 'react'
import clsx from 'clsx'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import StoreIcon from '@material-ui/icons/Store'
import ExitIcon from '@material-ui/icons/ExitToApp'
import CloseIcon from '@material-ui/icons/Close'
import CategoryIcon from '@material-ui/icons/Category'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import { makeStyles } from '@material-ui/core/styles'
import Products from './product/Products'
import ProductAdd from './product/ProductAdd'
import ProductEdit from './product/ProductEdit'
import Categories from './Categories'
import CategoryAdd from './CategoryAdd'
import CategoryEdit from './CategoryEdit'
import Orders from './Orders'
import Order from './Order'
import { ListItemLink } from '../elements'

const drawerWidth = 240
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.up('sm')]: {
      zIndex: theme.zIndex.drawer + 1,
    },
  },
  spacer: {
    flex: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerToolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    width: '100%',
    padding: theme.spacing(3),
  },
}))

const LINKS = [
  { icon: <StoreIcon />, text: 'products', to: '/' },
  { icon: <CategoryIcon />, text: 'categories', to: '/categories' },
  { icon: <ShoppingCartIcon />, text: 'orders', to: 'orders/succeeded' },
]

export default function Main() {
  const classes = useStyles()
  const client = useApolloClient()
  const navigate = useNavigate()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen)
  }

  async function handleSignOut() {
    localStorage.removeItem('token')
    await client.clearStore()
    client.resetStore()
    navigate('/sign-in')
  }

  const drawer = (
    <div>
      <div className={clsx(classes.toolbar, classes.drawerToolbar)}>
        <IconButton color="inherit" onClick={handleDrawerToggle}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {LINKS.map((link, index) => (
          <ListItemLink key={index} to={link.to}>
            <ListItemIcon>{link.icon}</ListItemIcon>
            <ListItemText primary={link.text} />
          </ListItemLink>
        ))}
      </List>
      <Divider />
    </div>
  )

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            kidzzlugano
          </Typography>
          <div className={classes.spacer} />
          <IconButton color="inherit" edge="end" onClick={handleSignOut}>
            <ExitIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div>
          <Routes>
            <Route path="/" element={<Products />} />
            <Route path="product/add" element={<ProductAdd />} />
            <Route path="product/edit/:id" element={<ProductEdit />} />
            <Route path="categories" element={<Categories />} />
            <Route path="category/add" element={<CategoryAdd />} />
            <Route path="category/edit/:id" element={<CategoryEdit />} />
            <Route path="orders/:tab" element={<Orders />} />
            <Route path="order/:id" element={<Order />} />
          </Routes>
        </div>
      </main>
    </div>
  )
}
