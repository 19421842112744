import React from 'react'
import * as yup from 'yup'
import { Navigate } from 'react-router-dom'
import { gql, useQuery, useMutation } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import { useForm, utils } from '@kidzzzlugano/core'
import { Error } from '../../elements'
import FormVariants from './FormVariants'

const useStyles = makeStyles(() => ({
  submit: {
    marginTop: 32,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}))

const GET_CATEGORIES = gql`
  {
    getCategories {
      id
      name
    }
  }
`

const ADD_PRODUCT = gql`
  mutation AddProduct($input: ProductInput) {
    addProduct(input: $input) {
      id
      title {
        lang
        value
      }
      description {
        lang
        value
      }
      categories {
        id
        name
      }
      brand
      quantity
      price
      images
    }
  }
`

function FormInformation({ form, categories, classes }) {
  const price =
    form.values.price && utils.formatStringToMoney(form.values.price)
  const discount = form.values.discount && parseInt(form.values.discount)
  const priceWithDiscount =
    price && discount ? price - (price / 100) * discount : undefined

  return (
    <>
      <Grid item xs={12} md={8}>
        <TextField
          {...form.getFieldProps('titleEn')}
          fullWidth
          multiline
          id="product-title-en"
          label="title"
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="start">EN</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          {...form.getFieldProps('descriptionEn')}
          fullWidth
          multiline
          id="product-description-en"
          label="description"
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="start">EN</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          {...form.getFieldProps('titleIt')}
          fullWidth
          multiline
          id="product-title-it"
          label="title"
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="start">IT</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          {...form.getFieldProps('descriptionIt')}
          fullWidth
          multiline
          id="product-description-it"
          label="description"
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="start">IT</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          {...form.getFieldProps('brand')}
          fullWidth
          id="product-brand"
          label="brand"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="categories-label">categories</InputLabel>
          <Select
            {...form.getFieldProps('categories')}
            multiple
            labelId="categories-label"
            id="categories-select"
            label="categories"
            renderValue={selected => (
              <div className={classes.chips}>
                {selected.map(value => (
                  <Chip
                    key={value.id}
                    label={value.name}
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
          >
            {categories.map(category => (
              <MenuItem key={category.id} value={category}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          {...form.getFieldProps('quantity')}
          fullWidth
          id="product-quantity"
          label="quantity"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          {...form.getFieldProps('price')}
          fullWidth
          id="product-price"
          label="price"
          variant="outlined"
          InputProps={
            priceWithDiscount && {
              endAdornment: (
                <InputAdornment position="start">
                  {utils.formatMoneyToString(priceWithDiscount)}
                </InputAdornment>
              ),
            }
          }
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          {...form.getFieldProps('discount')}
          fullWidth
          id="product-discount"
          label="discount"
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
      </Grid>
    </>
  )
}

function FormImages({ files, onChange }) {
  const images = files?.map(file => URL.createObjectURL(file))

  return (
    <>
      <Grid item xs={12} md={8}>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="button-file"
          multiple
          type="file"
          onChange={onChange}
        />
        <label htmlFor="button-file">
          <Button component="span" fullWidth>
            Upload Images
          </Button>
        </label>
      </Grid>
      <Grid container item xs={12} md={8} spacing={1}>
        {images?.map(image => (
          <Grid key={image} item xs={12} md={6}>
            <img width="100%" height="auto" src={image} />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

const schema = yup.object().shape({
  titleEn: yup.string().required(),
  descriptionEn: yup.string().required(),
  titleIt: yup.string().required(),
  descriptionIt: yup.string().required(),
  brand: yup.string(),
  categories: yup.array().required(),
  quantity: yup.number().required(),
  price: yup
    .string()
    .matches(/^\d+(?:\.\d{2})?$/)
    .required(),
  discount: yup
    .number()
    .min(5)
    .max(99)
    .transform((cv, ov) => (ov === '' ? undefined : cv))
    .nullable(),
  options: yup.array().of(
    yup.object().shape({
      name: yup.string().required(),
      values: yup.array().of(yup.string()),
    }),
  ),
  variants: yup.array().of(
    yup
      .object()
      .shape({
        key: yup.string(),
        checked: yup.boolean(),
        quantity: yup
          .number()
          .transform((cv, ov) => (ov === '' ? undefined : cv)),
      })
      .nullable(),
  ),
})

function Form({ categories }) {
  const classes = useStyles()
  const [tab, setTab] = React.useState(0)
  const [files, setFiles] = React.useState()
  const [addProduct, { error }] = useMutation(ADD_PRODUCT)
  const form = useForm(
    { categories: [] },
    {
      schema,
      onSubmit: async values => {
        const variants = values.variants?.length &&
          values.options?.length && {
            options: values.options,
            values: values.variants
              .filter(({ checked }) => checked)
              .map(({ key, quantity }) => ({
                key,
                quantity: parseInt(
                  quantity != null && quantity !== ''
                    ? quantity
                    : values.quantity,
                ),
              })),
          }
        const input = {
          title: [
            { lang: 'en', value: values.titleEn },
            { lang: 'it', value: values.titleIt },
          ],
          description: [
            { lang: 'en', value: values.descriptionEn },
            { lang: 'it', value: values.descriptionIt },
          ],
          categories: values.categories.map(category => category.id),
          ...(values.brand && { brand: values.brand }),
          quantity: parseInt(values.quantity),
          price: utils.formatStringToMoney(values.price),
          ...(values.discount && { discount: parseInt(values.discount) }),
          ...(files?.length && { images: files }),
          ...(variants && { variants: variants }),
        }
        await addProduct({ variables: { input } })
      },
    },
  )

  if (!error && form.isSubmitted) {
    return <Navigate to="/" replace />
  }

  function handleFileChange({ target: { files } }) {
    setFiles(Array.from(files))
  }

  function handelKeyPress(e) {
    const key = e.charCode || e.keyCode || 0
    const textarea = e.target.tagName === 'TEXTAREA'
    if (key === 13 && !textarea) {
      e.preventDefault()
    }
  }

  return (
    <form {...form.getFormProps()} onKeyPress={handelKeyPress}>
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        <Grid item>
          <Typography variant="h5" noWrap>
            Add Product
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Tabs
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            value={tab}
            onChange={(_, tab) => setTab(tab)}
          >
            <Tab label="Information" />
            <Tab label="Images" />
            <Tab label="Variants" />
          </Tabs>
        </Grid>
        {tab === 0 && (
          <FormInformation
            classes={classes}
            form={form}
            categories={categories}
          />
        )}
        {tab === 1 && <FormImages files={files} onChange={handleFileChange} />}
        {tab === 2 && <FormVariants form={form} />}
        {error && (
          <Grid item xs={12}>
            <Error>{error.message}</Error>
          </Grid>
        )}
        <Grid item xs={12} md={8}>
          <Button
            {...form.getSubmitButtonProps()}
            fullWidth
            className={classes.submit}
            variant="contained"
            color="primary"
            size="large"
          >
            {form.isSubmitting ? (
              <CircularProgress color="inherit" size={18} thickness={8} />
            ) : (
              'Submit'
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default function ProductAdd() {
  const { loading, error, data } = useQuery(GET_CATEGORIES)

  if (error) {
    return <Error>{error.message}</Error>
  }

  if (loading && !data?.getCategories) {
    return <CircularProgress />
  }

  return <Form categories={data.getCategories} />
}
