import React from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import CircularProgress from '@material-ui/core/CircularProgress'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Navigate, useParams } from 'react-router-dom'
import { gql, useQuery, useMutation } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import { utils } from '@kidzzzlugano/core'
import { useConfig } from '../providers'
import { Error } from '../elements'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  radioGroup: {
    flexDirection: 'row',
  },
})

const GET_ORDER = gql`
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      price
      intent
      status
      products {
        variant
        quantity
        product {
          id
          brand
          title {
            lang
            value
          }
          price
          discount
          images
          variants {
            options {
              name
            }
          }
        }
      }
    }
  }
`

const UPDATE_ORDER = gql`
  mutation UpdateOrder($id: ID!, $input: OrderInput!) {
    updateOrder(id: $id, input: $input) {
      id
      status
    }
  }
`

function OrderView({ id, order }) {
  const classes = useStyles()
  const [updateOrder, { loading, error, data }] = useMutation(UPDATE_ORDER)
  const [status, setStatus] = React.useState(order.status)

  function handleUpdate() {
    updateOrder({ variables: { id, input: { status } } })
  }

  if (data?.updateOrder.id) {
    return <Navigate to={`/orders/${status}`} />
  }

  return (
    <Grid container direction="column" wrap="nowrap" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" noWrap>
          Order: {order.intent}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" noWrap>
          Total: {`${utils.formatMoneyToString(order.price)} CHF`}
        </Typography>
      </Grid>
      <Grid container item>
        <Grid item xs={12} md={6}>
          <RadioGroup
            className={classes.radioGroup}
            name="quiz"
            value={status}
            onChange={e => setStatus(e.target.value)}
          >
            {['succeeded', 'shipped', 'refunded', 'incomplete'].map(value => (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio color="primary" />}
                label={value}
              />
            ))}
          </RadioGroup>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleUpdate}
          >
            {loading ? <CircularProgress /> : 'Update'}
          </Button>
        </Grid>
      </Grid>
      {error && (
        <Grid item xs={12}>
          <Error>{error.message}</Error>
        </Grid>
      )}
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table className={classes.tables}>
            <TableHead>
              <TableRow>
                <TableCell>PICTURE</TableCell>
                <TableCell>PRODUCT</TableCell>
                <TableCell>PRICE</TableCell>
                <TableCell>QUANTITY</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.products.map((product, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <img width="128px" height="auto" src={product.image} />
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">{product.brand}</Typography>
                    <Typography variant="subtitle1">{product.title}</Typography>
                    {product.variants?.map(variant => (
                      <Typography key={variant} variant="subtitle2">
                        {variant}
                      </Typography>
                    ))}
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">
                      {`${utils.formatMoneyToString(product.price)} CHF`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">{product.quantity}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default function Order() {
  const { id } = useParams()
  const { assets } = useConfig()
  const { loading, data, error } = useQuery(GET_ORDER, { variables: { id } })

  if (error) {
    return <Error>{error.message}</Error>
  }

  if (loading && !data?.getOrder) {
    return <CircularProgress />
  }

  const order = {
    ...data.getOrder,
    products: data.getOrder.products.map(({ product, variant, quantity }) => {
      return {
        ...product,
        quantity,
        title: product.title.find(({ lang }) => lang === 'en')?.value,
        image: `${assets}${product.images[0]}`,
        price: product.discount
          ? product.price - (product.price / 100) * product.discount
          : product.price,
        variants: variant
          ?.split('/')
          .map(
            (variant, i) => `${product.variants.options[i].name}: ${variant}`,
          ),
      }
    }),
  }

  return <OrderView id={id} order={order} />
}
