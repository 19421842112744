import React from 'react'
import {
  ApolloProvider as Provider,
  ApolloClient,
  InMemoryCache,
} from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { useConfig } from '../providers'

export default function ApolloProvider({ children }) {
  const config = useConfig()
  const uploadLink = createUploadLink({
    uri: config.graphqlUri,
    fetchOptions: { mode: 'cors' },
  })

  const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        switch (error.extensions.code) {
          case 'UNAUTHENTICATED':
            localStorage.removeItem('token')
            window.location = '/'
            break
          default:
            break
        }
      }
    }
  })

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token')
    return {
      headers: {
        ...headers,
        'Access-Control-Allow-Origin': '*',
        ...(token && { token }),
      },
    }
  })
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(errorLink.concat(uploadLink)),
  })
  return <Provider client={client}>{children}</Provider>
}
