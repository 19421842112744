import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { gql, useQuery, useLazyQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { utils } from '@kidzzzlugano/core'
import { Error } from '../elements'

const GET_ORDERS = gql`
  query GetOrders($status: String!, $skip: Int!, $limit: Int!) {
    getOrders(status: $status, skip: $skip, limit: $limit) {
      total
      orders {
        id
        price
        createdAt
        updatedAt
      }
    }
  }
`

const GET_ORDER = gql`
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      price
      createdAt
      updatedAt
    }
  }
`

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

const ROWS_PER_PAGE = 5
function DataTable({ status }) {
  const classes = useStyles()
  const navigate = useNavigate()
  const { loading, error, data, fetchMore } = useQuery(GET_ORDERS, {
    fetchPolicy: 'cache-and-network',
    variables: { status, skip: 0, limit: ROWS_PER_PAGE },
  })
  const [page, setPage] = React.useState(0)

  if (error) {
    return <Error>{error.message}</Error>
  }

  if (loading) {
    return <CircularProgress />
  }

  const count = data.getOrders.total
  const orders = data.getOrders.orders
    .slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE)
    .map(order => ({
      ...order,
      price: `${utils.formatMoneyToString(order.price)} CHF`,
      createdAt: utils.formatDate(order.createdAt),
      updatedAt: order.updatedAt && utils.formatDate(order.updatedAt),
    }))

  function handlePageChange(_, page) {
    if (page * ROWS_PER_PAGE < data.getOrders.orders.length) {
      setPage(page)
      return
    }

    fetchMore({
      variables: {
        status,
        skip: page * ROWS_PER_PAGE,
        limit: ROWS_PER_PAGE,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setPage(page)
        return {
          ...prev,
          getOrders: {
            total: fetchMoreResult.getOrders.total,
            orders: [
              ...prev.getOrders.orders,
              ...fetchMoreResult.getOrders.orders,
            ],
          },
        }
      },
    })
  }

  return (
    <Paper>
      <TableContainer>
        <Table className={classes.tables}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>PRICE</TableCell>
              <TableCell>CREATED AT</TableCell>
              <TableCell>UPDATED AT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map(order => (
              <TableRow
                hover
                key={order.id}
                onClick={() => navigate(`/order/${order.id}`)}
              >
                <TableCell>{order.id}</TableCell>
                <TableCell>{order.price}</TableCell>
                <TableCell>{order.createdAt}</TableCell>
                <TableCell>{order.updatedAt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        page={page}
        count={count}
        rowsPerPage={ROWS_PER_PAGE}
        rowsPerPageOptions={[ROWS_PER_PAGE]}
        onChangePage={handlePageChange}
      />
    </Paper>
  )
}

function OrderSearch() {
  const classes = useStyles()
  const navigate = useNavigate()
  const [getOrder, { loading, error, data }] = useLazyQuery(GET_ORDER)
  const [search, setSearch] = React.useState('')

  function handleSubmit(e) {
    e.preventDefault()

    getOrder({ variables: { id: search.trim() } })
  }

  return (
    <>
      <Grid item>
        <Paper>
          <Box p={4}>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                id="search-field"
                label="Search order"
                variant="outlined"
                disabled={loading}
                value={search}
                onChange={e => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit" disabled={loading}>
                        {loading ? <CircularProgress /> : <SearchIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Box>
        </Paper>
      </Grid>
      {data?.getOrder && (
        <Grid item>
          <TableContainer component={Paper}>
            <Table className={classes.tables}>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>PRICE</TableCell>
                  <TableCell>CREATED AT</TableCell>
                  <TableCell>UPDATED AT</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  hover
                  key={data.getOrder.id}
                  onClick={() => navigate(`/order/${data.getOrder.id}`)}
                >
                  <TableCell>{data.getOrder.id}</TableCell>
                  <TableCell>
                    {`${utils.formatMoneyToString(data.getOrder.price)}} CHF`}
                  </TableCell>
                  <TableCell>{data.getOrder.createdAt}</TableCell>
                  <TableCell>{data.getOrder.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
      {error && (
        <Grid item>
          <Error>{error.message}</Error>
        </Grid>
      )}
    </>
  )
}

export default function Orders() {
  const { tab } = useParams()
  const navigate = useNavigate()
  const matches = useMediaQuery('(min-width:600px)')

  return (
    <Grid container direction="column" wrap="nowrap" spacing={2}>
      <Grid item>
        <Typography variant="h5" noWrap>
          Orders
        </Typography>
      </Grid>
      <Grid item>
        <Paper>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_, tab) => navigate(`/orders/${tab}`)}
            variant={matches ? 'fullWidth' : 'scrollable'}
          >
            <Tab label="Succeeded" value="succeeded" />
            <Tab label="Shipped" value="shipped" />
            <Tab label="Refunded" value="refunded" />
            <Tab label="Incomplete" value="incomplete" />
            <Tab label="Search" value="search" />
          </Tabs>
        </Paper>
      </Grid>
      {tab === 'search' ? (
        <OrderSearch />
      ) : (
        <Grid item>
          <DataTable key={tab} status={tab} />
        </Grid>
      )}
    </Grid>
  )
}
