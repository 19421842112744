import React from 'react'
import * as yup from 'yup'
import { Navigate, useParams } from 'react-router-dom'
import { gql, useQuery, useMutation } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CircularProgress from '@material-ui/core/CircularProgress'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'
import { useForm, utils } from '@kidzzzlugano/core'
import FormVariants, { cartesian } from './FormVariants'
import { Error } from '../../elements'
import { useConfig } from '../../providers/ConfigProvider'

const useStyles = makeStyles(() => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  radioGroup: {
    flexDirection: 'row',
  },
}))

const GET_PRODUCT_AND_CATEGORIES = gql`
  query GetProductAndCategories($id: ID!) {
    getProduct(id: $id) {
      id
      title {
        lang
        value
      }
      description {
        lang
        value
      }
      categories {
        id
        name
      }
      brand
      price
      discount
      quantity
      images
      variants {
        options {
          name
          values
        }
        values {
          key
          quantity
        }
      }
    }
    getCategories {
      id
      name
    }
  }
`

const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($id: ID!, $input: ProductUpdateInput) {
    updateProduct(id: $id, input: $input) {
      id
      title {
        lang
        value
      }
      description {
        lang
        value
      }
      categories {
        id
        name
      }
      brand
      quantity
      price
      discount
      images
      variants {
        options {
          name
          values
        }
        values {
          key
          quantity
        }
      }
    }
  }
`

const DELETE_PRODUCT = gql`
  mutation DeleteCategory($id: ID!) {
    deleteProduct(id: $id) {
      id
    }
  }
`

const schema = yup.object().shape({
  titleEn: yup.string().required(),
  descriptionEn: yup.string().required(),
  titleIt: yup.string().required(),
  descriptionIt: yup.string().required(),
  brand: yup.string().nullable(),
  categories: yup.array().required(),
  quantity: yup.number().required(),
  price: yup
    .string()
    .matches(/^\d+(?:\.\d{2})?$/)
    .required(),
  discount: yup
    .number()
    .min(5)
    .max(99)
    .transform((cv, ov) => (ov === '' ? undefined : cv))
    .nullable(),
  resetImages: yup.string().required(),
  options: yup.array().of(
    yup.object().shape({
      name: yup.string().required(),
      values: yup.array().of(yup.string()),
    }),
  ),
  variants: yup.array().of(
    yup.object().shape({
      key: yup.string(),
      checked: yup.boolean(),
      quantity: yup
        .number()
        .transform((cv, ov) => (ov === '' || ov == null ? undefined : cv)),
    }),
  ),
})

function Transition(props) {
  return <Slide direction="up" {...props} />
}

function FormInformation({ form, categories, classes }) {
  const price =
    form.values.price && utils.formatStringToMoney(form.values.price)
  const discount = form.values.discount && parseInt(form.values.discount)
  const priceWithDiscount =
    price && discount ? price - (price / 100) * discount : undefined

  return (
    <>
      <Grid item xs={12} md={8}>
        <TextField
          {...form.getFieldProps('titleEn')}
          multiline
          fullWidth
          id="product-title-en"
          label="title"
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="start">EN</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          {...form.getFieldProps('descriptionEn')}
          fullWidth
          multiline
          id="product-description-en"
          label="description"
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="start">EN</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          {...form.getFieldProps('titleIt')}
          multiline
          fullWidth
          id="product-title-it"
          label="title"
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="start">IT</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          {...form.getFieldProps('descriptionIt')}
          fullWidth
          multiline
          id="product-description-it"
          label="description"
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="start">IT</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          {...form.getFieldProps('brand')}
          fullWidth
          id="product-brand"
          label="brand"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="categories-label">categories</InputLabel>
          <Select
            {...form.getFieldProps('categories')}
            multiple
            labelId="categories-label"
            id="categories-select"
            label="categories"
            renderValue={selected => (
              <div className={classes.chips}>
                {selected.map(value => (
                  <Chip
                    key={value.id}
                    label={value.name}
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
          >
            {categories.map(category => (
              <MenuItem key={category.id} value={category}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          {...form.getFieldProps('quantity')}
          fullWidth
          id="product-quantity"
          label="quantity"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          {...form.getFieldProps('price')}
          fullWidth
          id="product-price"
          label="price"
          variant="outlined"
          InputProps={
            priceWithDiscount && {
              endAdornment: (
                <InputAdornment position="start">
                  {utils.formatMoneyToString(priceWithDiscount)}
                </InputAdornment>
              ),
            }
          }
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          {...form.getFieldProps('discount')}
          fullWidth
          id="product-discount"
          label="discount"
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
      </Grid>
    </>
  )
}

function FormImages({ classes, form, images, files, onChange }) {
  const { assets } = useConfig()
  let pictures

  if (form.values.resetImages === 'update') {
    pictures = [
      images?.map(image => `${assets}${image}`),
      files?.map(file => URL.createObjectURL(file)),
    ]
      .filter(Boolean)
      .flat()
  } else {
    pictures = files
      ? files.map(file => URL.createObjectURL(file))
      : images?.map(image => `${assets}${image}`)
  }

  return (
    <>
      <Grid item xs={12} md={8}>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="button-file"
          multiple
          type="file"
          onChange={onChange}
        />
        <label htmlFor="button-file">
          <Button component="span" fullWidth>
            Upload Images
          </Button>
        </label>
      </Grid>
      <Grid item xs={12} md={8}>
        <RadioGroup
          className={classes.radioGroup}
          name="quiz"
          {...form.getFieldProps('resetImages')}
        >
          <FormControlLabel
            value="update"
            control={<Radio color="primary" />}
            label="Update images"
          />
          <FormControlLabel
            value="reset"
            control={<Radio color="primary" />}
            label="Reset images"
          />
        </RadioGroup>
      </Grid>
      <Grid container item xs={12} md={8} spacing={1}>
        {pictures?.map(image => (
          <Grid key={image} item xs={12} md={6}>
            <img width="100%" height="auto" src={image} />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

function Form({ id, data, categories }) {
  const classes = useStyles()
  const [tab, setTab] = React.useState(0)
  const [files, setFiles] = React.useState()
  const [updateProduct, { error }] = useMutation(UPDATE_PRODUCT)
  const [deleteProduct] = useMutation(DELETE_PRODUCT)
  const [showDeleteDialog, setShowDeleteDialog] = React.useState()
  const form = useForm(data, {
    schema,
    onSubmit: async values => {
      const variants = values.variants && {
        options: values.options,
        values: values.variants
          .filter(({ checked }) => checked)
          .map(({ key, quantity }) => ({
            key,
            quantity: parseInt(
              quantity != null && quantity !== '' ? quantity : values.quantity,
            ),
          })),
      }
      const input = {
        title: [
          { lang: 'en', value: values.titleEn },
          { lang: 'it', value: values.titleIt },
        ],
        description: [
          { lang: 'en', value: values.descriptionEn },
          { lang: 'it', value: values.descriptionIt },
        ],
        categories: values.categories.map(category => category.id),
        brand: values.brand,
        quantity: parseInt(values.quantity),
        price: utils.formatStringToMoney(values.price),
        discount: values.discount ? parseInt(values.discount) : null,
        ...(files?.length && {
          images: files,
          resetImages: values.resetImages === 'reset',
        }),
        variants,
      }
      await updateProduct({ variables: { id, input } })
    },
  })

  if (!error && form.isSubmitted) {
    return <Navigate to="/" replace />
  }

  function handleFileChange({ target: { files } }) {
    setFiles(Array.from(files))
  }

  async function handleDeleteProduct() {
    setShowDeleteDialog(false)
    form.setIsSubmitting(true)
    await deleteProduct({ variables: { id } })
    form.setIsSubmitted(true)
    form.setIsSubmitting(false)
  }

  return (
    <>
      <form {...form.getFormProps()}>
        <Grid container direction="column" wrap="nowrap" spacing={2}>
          <Grid item>
            <Typography variant="h5" noWrap>
              Update Product
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Tabs
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              value={tab}
              onChange={(_, tab) => setTab(tab)}
            >
              <Tab label="Information" />
              <Tab label="Images" />
              <Tab label="Variants" />
            </Tabs>
          </Grid>
          {tab === 0 && (
            <FormInformation
              classes={classes}
              form={form}
              categories={categories}
            />
          )}
          {tab === 1 && (
            <FormImages
              classes={classes}
              form={form}
              images={data.images}
              files={files}
              onChange={handleFileChange}
            />
          )}
          {tab === 2 && <FormVariants form={form} />}
          {error && (
            <Grid item xs={12}>
              <Error>{error.message}</Error>
            </Grid>
          )}
          <Grid item xs={12} md={8}>
            <Button
              {...form.getSubmitButtonProps()}
              disabled={
                (!form.isDirty && !files) ||
                form.isValidating ||
                form.isSubmitting
              }
              fullWidth
              variant="contained"
              color="primary"
              size="large"
            >
              {form.isSubmitting ? (
                <CircularProgress color="inherit" size={18} thickness={8} />
              ) : (
                'Update'
              )}
            </Button>
          </Grid>
          <Grid item xs={12} md={8}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => setShowDeleteDialog(true)}
            >
              {form.isSubmitting ? (
                <CircularProgress color="inherit" size={18} thickness={8} />
              ) : (
                'Delete'
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Dialog
        open={showDeleteDialog}
        keepMounted
        TransitionComponent={Transition}
        onClick={() => setShowDeleteDialog(false)}
      >
        <DialogTitle>Delete Product</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setShowDeleteDialog(false)}>
            No
          </Button>
          <Button color="primary" onClick={() => handleDeleteProduct()}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default function ProductEdit() {
  const { id } = useParams()
  const { loading, error, data } = useQuery(GET_PRODUCT_AND_CATEGORIES, {
    variables: { id },
  })

  if (error) {
    return <Error>{error.message}</Error>
  }

  if (loading && !data?.getCategories) {
    return <CircularProgress />
  }

  const options = data.getProduct?.variants?.options
    .map(option => option.values)
    .filter(values => values.length)
  const values = data.getProduct?.variants?.values
    .map(value => ({
      ...value,
      checked: true,
    }))
    .reduce((acc, value) => {
      acc[value.key] = value
      return acc
    }, {})
  const variants =
    options?.length &&
    cartesian(...options)
      .map(value => value.join('/'))
      .map(key => values[key] || { key, checked: false })

  const initialData = {
    titleEn: data.getProduct.title.find(title => title.lang === 'en')?.value,
    titleIt: data.getProduct.title.find(title => title.lang === 'it')?.value,
    descriptionEn: data.getProduct.description.find(
      description => description.lang === 'en',
    )?.value,
    descriptionIt: data.getProduct.description.find(
      description => description.lang === 'it',
    )?.value,
    brand: data.getProduct.brand,
    categories: data.getProduct.categories.map(a =>
      data.getCategories.find(b => a.id === b.id),
    ),
    quantity: data.getProduct.quantity,
    price: utils.formatMoneyToString(data.getProduct.price),
    discount: data.getProduct.discount,
    images: data.getProduct.images,
    options: data.getProduct?.variants?.options?.map(({ name, values }) => ({
      name,
      values,
    })),
    ...(variants && { variants }),
    resetImages: 'update',
  }

  return <Form id={id} data={initialData} categories={data.getCategories} />
}
