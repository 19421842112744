import React from 'react'
import * as yup from 'yup'
import { Navigate } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useForm } from '@kidzzzlugano/core'
import { Error } from '../elements'

const ADD_CATEGORY = gql`
  mutation AddCategory($name: String) {
    addCategory(name: $name) {
      id
      name
    }
  }
`

const schema = yup.object().shape({
  name: yup.string().required(),
})

export default function CategoryAdd() {
  const [addCategory, { error }] = useMutation(ADD_CATEGORY)
  const form = useForm(
    { name: '' },
    {
      schema,
      onSubmit: async values => {
        await addCategory({ variables: { name: values.name } })
      },
    },
  )

  if (!error && form.isSubmitted) {
    return <Navigate to="/categories" replace />
  }

  return (
    <form {...form.getFormProps()}>
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        <Grid item>
          <Typography variant="h5" noWrap>
            Add Category
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            {...form.getFieldProps('name')}
            fullWidth
            multiline
            id="category-name"
            label="name"
            variant="outlined"
          />
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Error>{error.message}</Error>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Button
            {...form.getSubmitButtonProps()}
            fullWidth
            variant="contained"
            color="primary"
            size="large"
          >
            {form.isSubmitting ? (
              <CircularProgress color="inherit" size={18} thickness={8} />
            ) : (
              'Submit'
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
