import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import {
  ConfigProvider,
  ApolloProvider,
  ThemeProvider,
} from './components/providers'
import { Main, SignIn } from './components/views'

function PrivateRoute() {
  const token = localStorage.getItem('token')
  if (!token) {
    return <Navigate to="/sign-in" replace />
  }

  return <Main />
}

export default function App() {
  return (
    <ConfigProvider>
      <ApolloProvider>
        <ThemeProvider>
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              <Route path="sign-in" element={<SignIn />} />
              <Route path="/*" element={<PrivateRoute />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </ApolloProvider>
    </ConfigProvider>
  )
}
