import React from 'react'
import { gql, useQuery } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Avatar from '@material-ui/core/Avatar'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import List from '@material-ui/core/List'
import Button from '@material-ui/core/Button'
import ImageIcon from '@material-ui/icons/Image'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'
import { Error, ListItemLink, ButtonLink } from '../elements'

const GET_CATEGORIES = gql`
  {
    getCategories {
      id
      name
    }
  }
`

export default function Categories() {
  const { loading, error, data } = useQuery(GET_CATEGORIES, {
    fetchPolicy: 'cache-and-network',
  })
  const [search, setSearch] = React.useState()

  if (error) {
    return <Error>{error.message}</Error>
  }

  if (loading && !data?.getCategories) {
    return <CircularProgress />
  }

  const categories = data.getCategories.filter(
    category =>
      !search || category.name.toLowerCase().includes(search.toLowerCase()),
  )

  return (
    <Grid container direction="column" wrap="nowrap" spacing={2}>
      <Grid item>
        <Typography variant="h5" noWrap>
          Categories
        </Typography>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={9}>
          <TextField
            fullWidth
            id="search-field"
            label="Search product"
            variant="outlined"
            value={search}
            onChange={e => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            fullWidth
            color="primary"
            size="large"
            component={ButtonLink}
            to="/category/add"
            style={{ height: '100%' }}
            startIcon={<AddIcon />}
          >
            Add Category
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <List>
          {categories.map(category => (
            <ListItemLink
              key={category.id}
              to={`/category/edit/${category.id}`}
            >
              <ListItemAvatar>
                <Avatar>
                  <ImageIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={category.name} />
              <ListItemIcon>
                <NavigateNextIcon />
              </ListItemIcon>
            </ListItemLink>
          ))}
        </List>
      </Grid>
    </Grid>
  )
}
