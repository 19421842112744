export function copy(object) {
  return JSON.parse(JSON.stringify(object))
}

export function stringToPath(path) {
  return path.split('.').reduce((acc, key) => {
    acc.push(...key.split(/\[([0-9]+)\]/).filter(k => k))
    return acc
  }, [])
}

export function get(object, path, defaultValue) {
  const array = typeof path === 'string' ? stringToPath(path) : path
  return (
    array.reduce((acc, key) => {
      acc = acc && acc[key]
      return acc
    }, object) ?? defaultValue
  )
}

export function set(object, path, value) {
  const array = typeof path === 'string' ? stringToPath(path) : path
  const length = array.length

  let index = -1
  let node = object

  while (++index < length) {
    let next = value
    if (index !== length - 1) {
      next = node[array[index]] || (isNaN(parseInt(array[index + 1])) ? {} : [])
    }
    node = node[array[index]] = next
  }

  return object
}

export function formatStringToMoney(value) {
  if (value.includes('.')) {
    return parseInt(value.replace(/\./g, ''))
  }

  return parseInt(value) * 100
}

export const formatDate = iso => {
  const date = new Date(iso)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  return `${day}.${month}.${year} ${hours}:${minutes}`
}

export function formatMoneyToString(value) {
  const first = String(value).slice(0, -2) || '0'
  const second = String(value).slice(-2).padStart(2, '0')
  return `${first}.${second}`
}

export function wrap(x, min, max) {
  return ((((x - min) % (max - min)) + (max - min)) % (max - min)) + min
}
