import React from 'react'
import { copy, get, set } from '../../utils'

function schemaHasAsyncValidation(schema, values) {
  try {
    schema.validateSync(values)
  } catch (error) {
    if (error.message.includes('Promise')) return true
  }

  return false
}

function formatYupErrors(yupError) {
  return yupError.inner.reduce((errors, error) => {
    set(errors, error.path == null ? error.type : error.path, error.message)
    return errors
  }, {})
}

function removeField(values, path, index) {
  const value = get(values, path)

  return value
    ? set(
        copy(values),
        path,
        value.reduce((acc, value, i) => {
          if (index !== i) {
            acc.push(value)
          }
          return acc
        }, []),
      )
    : values
}

export default function useForm(initialValues, { schema, onSubmit }) {
  const [lastValues, setLastValues] = React.useState(initialValues)
  const [values, setValues] = React.useState(initialValues)
  const [errors, setErrors] = React.useState({})
  const [isValidating, setIsValidating] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [isSubmitted, setIsSubmitted] = React.useState(false)

  const isDirty = JSON.stringify(lastValues) !== JSON.stringify(values)

  function deleteField(path, index) {
    setValues(removeField(values, path, index))
    setErrors(removeField(errors, path, index))
  }

  function clearErrors() {
    setErrors({})
  }

  function validate() {
    return new Promise(async (resolve, reject) => {
      const isAsync = schemaHasAsyncValidation(schema, values)

      try {
        const validationMethod = isAsync ? 'validate' : 'validateSync'
        clearErrors()

        if (isAsync) {
          setIsValidating(true)
        }

        await schema[validationMethod](values, { abortEarly: false })
        resolve()
      } catch (error) {
        setErrors(formatYupErrors(error))
        reject(error)
      } finally {
        if (isAsync) {
          setIsValidating(false)
        }
      }
    })
  }

  function reset() {
    setValues(lastValues)
    clearErrors()
  }

  async function submit() {
    try {
      await validate()
    } catch (error) {
      return
    }

    try {
      setIsSubmitting(true)
      await onSubmit(values)
      setLastValues(values)
      setIsSubmitted(true)
    } finally {
      setIsSubmitting(false)
    }
  }

  function getFormProps() {
    return {
      onSubmit: e => {
        e.preventDefault()
        submit()
      },
    }
  }

  function getFieldProps(path, defaultValue = '') {
    return {
      id: path,
      name: path,
      disabled: isSubmitting,
      value: get(values, path, defaultValue),
      error: get(errors, path),
      onChange: e => {
        setValues(set(copy(values), path, e.target.value))
      },
    }
  }

  function getAddFieldButtonProps(path, index, value = '') {
    return {
      disabled: isSubmitting,
      onClick: () => {
        setValues(set(copy(values), `${path}[${index}]`, value))
      },
    }
  }

  function getRemoveFieldButtonProps(path, index) {
    return {
      disabled: isSubmitting,
      onClick: () => {
        setValues(removeField(values, path, index))
        setErrors(removeField(errors, path, index))
      },
    }
  }

  function getResetButtonProps() {
    return {
      type: 'button',
      disabled: !isDirty || isValidating || isSubmitting,
      onClick: reset,
    }
  }

  function getSubmitButtonProps() {
    return {
      type: 'submit',
      disabled: !isDirty || isValidating || isSubmitting,
    }
  }

  return {
    isDirty,
    isValidating,
    isSubmitting,
    isSubmitted,
    values,
    setValues,
    errors,
    setErrors,
    clearErrors,
    validate,
    reset,
    setIsSubmitted,
    setIsSubmitting,
    submit,
    getFormProps,
    getFieldProps,
    getAddFieldButtonProps,
    getRemoveFieldButtonProps,
    getResetButtonProps,
    getSubmitButtonProps,
    deleteField,
  }
}
