import React from 'react'
import * as yup from 'yup'
import { Navigate, useParams } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useForm } from '@kidzzzlugano/core'
import { Error } from '../elements'

const GET_CATEGORY = gql`
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
    }
  }
`

const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($id: ID!, $name: String) {
    updateCategory(id: $id, name: $name) {
      id
      name
    }
  }
`

const DELETE_CATEGORY = gql`
  mutation DeleteCategory($id: ID!) {
    deleteCategory(id: $id) {
      id
    }
  }
`

const schema = yup.object().shape({
  name: yup.string().required(),
})

function Transition(props) {
  return <Slide direction="up" {...props} />
}

function Form({ id, name }) {
  const [showDeleteDialog, setShowDeleteDialog] = React.useState()
  const [updateCategory, { error }] = useMutation(UPDATE_CATEGORY)
  const [deleteCategory] = useMutation(DELETE_CATEGORY)
  const form = useForm(
    { name },
    {
      schema,
      onSubmit: async values => {
        await updateCategory({ variables: { id, name: values.name } })
      },
    },
  )

  if (!error && form.isSubmitted) {
    return <Navigate to="/categories" replace />
  }

  async function handleDeleteCategory() {
    setShowDeleteDialog(false)
    form.setIsSubmitting(true)
    await deleteCategory({ variables: { id } })
    form.setIsSubmitted(true)
    form.setIsSubmitting(false)
  }

  return (
    <>
      <form {...form.getFormProps()}>
        <Grid container direction="column" wrap="nowrap" spacing={2}>
          <Grid item>
            <Typography variant="h5" noWrap>
              Update Category
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              {...form.getFieldProps('name')}
              fullWidth
              multiline
              id="category-name"
              label="name"
              variant="outlined"
            />
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Error>{error.message}</Error>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Button
              {...form.getSubmitButtonProps()}
              fullWidth
              variant="contained"
              color="primary"
              size="large"
            >
              {form.isSubmitting ? (
                <CircularProgress color="inherit" size={18} thickness={8} />
              ) : (
                'Update'
              )}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => setShowDeleteDialog(true)}
            >
              {form.isSubmitting ? (
                <CircularProgress color="inherit" size={18} thickness={8} />
              ) : (
                'Delete'
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Dialog
        open={showDeleteDialog}
        keepMounted
        TransitionComponent={Transition}
        onClick={() => setShowDeleteDialog(false)}
      >
        <DialogTitle>Delete Category</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete category?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setShowDeleteDialog(false)}>
            No
          </Button>
          <Button color="primary" onClick={() => handleDeleteCategory()}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default function CategoryEdit() {
  const { id } = useParams()
  const { loading, error, data } = useQuery(GET_CATEGORY, { variables: { id } })

  if (error) {
    return <Error>{error.message}</Error>
  }

  if (loading && !data?.getCategory) {
    return <CircularProgress />
  }

  return <Form {...data.getCategory} />
}
