import React from 'react'
import { ThemeProvider as Provider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core'

const palette = {
  primary: { main: '#84368b' },
  secondary: { main: '#ffea00' },
}
const themeName = 'Vivid Violet Turbo Brown Bear'

let theme = createMuiTheme({ palette, themeName })
theme = {
  ...theme,
  overrides: {},
}

export default function ThemeProvider({ children }) {
  return <Provider theme={theme}>{children}</Provider>
}
