import React from 'react'
import { gql, useQuery } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Avatar from '@material-ui/core/Avatar'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import List from '@material-ui/core/List'
import Button from '@material-ui/core/Button'
import ImageIcon from '@material-ui/icons/Image'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { Error, ListItemLink, ButtonLink } from '../../elements'
import { useConfig } from '../../providers/ConfigProvider'

const GET_PRODUCTS = gql`
  {
    getProducts {
      id
      title {
        lang
        value
      }
      description {
        lang
        value
      }
      images
      brand
      categories {
        id
        name
      }
    }
    getCategories {
      id
      name
    }
  }
`

function ProductsView({ data }) {
  const { assets } = useConfig()
  const [search, setSearch] = React.useState()
  const [filters, setFilters] = React.useState(() => {
    return data.getCategories
      .filter(category => !category.name.includes('/'))
      .reduce((acc, category) => {
        acc[category.name] = false
        return acc
      }, {})
  })

  const products = data.getProducts
    .map(product => {
      const title = product.title.find(title => title.lang === 'en')?.value
      const description = product.description.find(
        description => description.lang === 'en',
      )?.value
      const brand = product.brand
      return {
        ...product,
        description,
        title: brand ? `${title}  ·  ${brand}` : title,
        images: product?.images[0],
      }
    })
    .filter(product => {
      return (
        !Object.values(filters).some(filter => filter) ||
        product.categories.some(category => filters[category.name])
      )
    })
    .filter(product => {
      return (
        !search ||
        product.title.toLowerCase().includes(search.toLowerCase()) ||
        product.brand?.toLowerCase()?.includes(search.toLowerCase())
      )
    })

  function handleFilterChange(event) {
    setFilters({ ...filters, [event.target.name]: event.target.checked })
  }

  return (
    <Grid container direction="column" wrap="nowrap" spacing={2}>
      <Grid item>
        <Typography variant="h5" noWrap>
          Products
        </Typography>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={9}>
          <TextField
            fullWidth
            id="search-field"
            label="Search product"
            variant="outlined"
            value={search}
            onChange={e => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            fullWidth
            color="primary"
            size="large"
            component={ButtonLink}
            to="product/add"
            style={{ height: '100%' }}
            startIcon={<AddIcon />}
          >
            Add product
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <FormGroup row>
          {Object.entries(filters).map(([name, checked]) => (
            <FormControlLabel
              key={name}
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleFilterChange}
                  name={name}
                  color="primary"
                />
              }
              label={name}
            />
          ))}
        </FormGroup>
      </Grid>
      <Grid item>
        <List>
          {products.map(({ id, images, title, description, brand }) => (
            <ListItemLink key={id} to={`product/edit/${id}`}>
              <ListItemAvatar>
                {images ? (
                  <Avatar src={`${assets}${images}`} />
                ) : (
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText primary={title} secondary={description} />
              <ListItemIcon>
                <NavigateNextIcon />
              </ListItemIcon>
            </ListItemLink>
          ))}
        </List>
      </Grid>
    </Grid>
  )
}

export default function Products() {
  const { loading, error, data } = useQuery(GET_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
  })

  if (error) {
    return <Error>{error.message}</Error>
  }

  if (loading && !data?.getProducts) {
    return <CircularProgress />
  }

  return <ProductsView data={data} />
}
