import React from 'react'
import * as yup from 'yup'
import { Navigate } from 'react-router-dom'
import { gql, useLazyQuery } from '@apollo/client'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from '@kidzzzlugano/core'
import { Error } from '../elements'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.kidzzzlugano.ch">
        kidzzzlugano
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const SIGN_IN = gql`
  query SignIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      token
    }
  }
`

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
})

export default function SignIn() {
  const classes = useStyles()
  const [singIn, { error, data }] = useLazyQuery(SIGN_IN)
  const form = useForm(
    { email: '', password: '' },
    {
      schema,
      onSubmit: async values => {
        await singIn({
          variables: {
            email: values.email.trim(),
            password: values.password.trim(),
          },
        })
      },
    },
  )

  if (data?.signIn?.token) {
    localStorage.setItem('token', data.signIn.token)
    return <Navigate to="/" replace />
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate {...form.getFormProps()}>
          <TextField
            {...form.getFieldProps('email')}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            {...form.getFieldProps('password')}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            {...form.getSubmitButtonProps()}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {form.isSubmitting ? (
              <CircularProgress color="inherit" size={18} thickness={8} />
            ) : (
              'Sign In'
            )}
          </Button>
          {error && <Error>{error.message}</Error>}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}
