import React from 'react'
import config from '../../config'

const Context = React.createContext(null)

export function useConfig() {
  const context = React.useContext(Context)
  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider')
  }
  return context
}

export function withConfig(WrappedComponent) {
  return props => (
    <Context.Consumer>
      {value => <WrappedComponent {...props} config={value} />}
    </Context.Consumer>
  )
}

export default function ConfigProvider({ value, children }) {
  return <Context.Provider value={value || config}>{children}</Context.Provider>
}
