import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import ChipInput from 'material-ui-chip-input'
import Checkbox from '@material-ui/core/Checkbox'
import { utils } from '@kidzzzlugano/core'

export function cartesian(...args) {
  const result = []
  const max = args.length - 1

  function helper(array, i) {
    const length = args[i].length

    for (let j = 0; j < length; j++) {
      const combination = array.slice(0)
      combination.push(args[i][j])

      if (i === max) {
        result.push(combination)
      } else {
        helper(combination, i + 1)
      }
    }
  }

  helper([], 0)
  return result
}

export default function FormVariants({ form }) {
  function makeHandleAddChip(id) {
    return function handleAddChip(chip) {
      if (chip.match(/\//)) {
        return
      }

      const values = utils.copy(form.values)
      values.options[id].values.push(chip)

      const options = values.options
        .map(option => option.values)
        .filter(values => values.length)
      const variants = cartesian(...options).map(value => value.join('/'))
      const map = variants.reduce((acc, key) => {
        acc[key] = key
        return acc
      }, {})
      values.variants = (values.variants || []).filter(
        variant => map[variant.key],
      )

      for (let i = 0; i < variants.length; i++) {
        if (i >= values.variants.length) {
          values.variants.push({ key: variants[i], checked: true })
          continue
        }

        if (variants[i] !== values.variants[i].key) {
          values.variants.splice(i, 0, { key: variants[i], checked: true })
          continue
        }
      }

      form.setValues(values)
    }
  }

  function makeHandleDeleteChip(id) {
    return function handleDeleteChip(_, index) {
      const values = utils.copy(form.values)
      values.options[id].values.splice(index, 1)

      const options = values.options
        .map(option => option.values)
        .filter(values => values.length)

      const variants = (options.length
        ? cartesian(...options)
        : []
      ).map(value => value.join('/'))
      const map = variants.reduce((acc, key) => {
        acc[key] = key
        return acc
      }, {})
      values.variants = values.variants.filter(variant => map[variant.key])

      for (let i = 0; i < variants.length; i++) {
        if (i >= values.variants.length) {
          values.variants.push({ key: variants[i], checked: true })
          continue
        }

        if (variants[i] !== values.variants[i].key) {
          values.variants.splice(i, 0, { key: variants[i], checked: true })
          continue
        }
      }

      form.setValues(values)
    }
  }

  function handleAddOption() {
    const values = utils.copy(form.values)
    values.options = values.options || []
    values.options.push({ name: '', values: [] })
    form.setValues(values)
  }

  function makeHandleDeleteOption(id) {
    return function handleDeleteOption() {
      const values = utils.copy(form.values)
      values.options.splice(id, 1)

      const options = values.options
        .map(option => option.values)
        .filter(values => values.length)

      const variants = (options.length
        ? cartesian(...options)
        : []
      ).map(value => value.join('/'))
      const map = variants.reduce((acc, key) => {
        acc[key] = key
        return acc
      }, {})
      values.variants = (values.variants || []).filter(
        variant => map[variant.key],
      )

      for (let i = 0; i < variants.length; i++) {
        if (i >= values.variants.length) {
          values.variants.push({ key: variants[i], checked: true })
          continue
        }

        if (variants[i] !== values.variants[i].key) {
          values.variants.splice(i, 0, { key: variants[i], checked: true })
          continue
        }
      }

      form.setValues(values)
    }
  }

  function makeHandleVariantChecked(id) {
    return function handleVariantChecked(e) {
      const values = utils.copy(form.values)
      values.variants[id].checked = e.target.checked
      form.setValues(values)
    }
  }

  return (
    <>
      <Grid item xs={12} md={8}>
        <Button fullWidth onClick={handleAddOption}>
          Add option
        </Button>
      </Grid>
      {!!form.values?.options?.length && (
        <Grid container item xs={12} md={8} spacing={1}>
          {form.values.options.map((variant, index) => {
            return (
              <React.Fragment key={index}>
                <Grid item xs={12} md={4}>
                  <TextField
                    {...form.getFieldProps(`options[${index}].name`)}
                    fullWidth
                    multiline
                    label="option name"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={11} md={7}>
                  <ChipInput
                    fullWidth
                    label="option values"
                    variant="outlined"
                    value={variant.values}
                    onAdd={makeHandleAddChip(index)}
                    onDelete={makeHandleDeleteChip(index)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={makeHandleDeleteOption(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </React.Fragment>
            )
          })}
        </Grid>
      )}
      {!!form.values?.variants?.length && (
        <Grid container item xs={12} md={8} spacing={1}>
          {form.values.variants.map((variant, index) => (
            <React.Fragment key={variant.key}>
              <Grid item xs={2}>
                <Checkbox
                  color="primary"
                  checked={variant.checked}
                  onChange={makeHandleVariantChecked(index)}
                />
              </Grid>
              <Grid item xs={2}>
                {variant.key.split('/').map((option, i) => (
                  <Typography key={i} variant="subtitle2" component="div">
                    {option}
                  </Typography>
                ))}
              </Grid>
              <Grid item xs={8}>
                <TextField
                  {...form.getFieldProps(`variants[${index}].quantity`)}
                  fullWidth
                  label="quantity"
                  variant="outlined"
                />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      )}
    </>
  )
}
